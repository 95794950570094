import './bootstrap';
import {Modal} from "bootstrap";
import TomSelect from "tom-select";
import { v4 as uuidv4 } from 'uuid';
import.meta.glob([
    '../images/**',
]);

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
        return;
    }
    document.addEventListener('DOMContentLoaded', fn);
}

function handleDeleteModal(elements = []){
    const deleteModal = document.getElementById('modal-confirm-delete');
    if (deleteModal) {
        deleteModal.addEventListener('show.bs.modal', function (event) {
            const deleteUrl = event.relatedTarget.getAttribute('data-delete-url');

            let message = event.relatedTarget.getAttribute('data-message');
            if (message == '' || message == 'undefined' || message == null) {
                message = deleteModal.querySelector('.modal-body-default').innerHTML;
            }

            const deleteForm = deleteModal.querySelector('.delete-form');
            const body = deleteModal.querySelector('.modal-body');

            deleteForm.action = deleteUrl;
            body.innerHTML = message;
        });
    }

    const confirmModal = document.getElementById('modal-confirm-link');
    if (confirmModal) {
        confirmModal.addEventListener('show.bs.modal', function (event) {
            const linkUrl = event.relatedTarget.getAttribute('data-link-url');

            let message = event.relatedTarget.getAttribute('data-message');
            if (message == '' || message == 'undefined' || message == null) {
                message = confirmModal.querySelector('.modal-body-default').innerHTML;
            }

            const link = confirmModal.querySelector('.modal-confirm-link');
            const body = confirmModal.querySelector('.modal-body');

            link.href = linkUrl;
            body.innerHTML = message;
        });
    }
}

function handleDataFormSubmit() {
    const dataFormSubmitId = document.querySelectorAll('[data-form-submit-id]')
    dataFormSubmitId.forEach(function (e) {
        e.addEventListener('click', function () {
            document.getElementById(this.dataset.formSubmitId).click()
        })
    })
}

function handleChangeForNewProduct(dosageField) {
    return function() {
        if (dosageField && window.pdw_productOptionsDosage) {
            dosageField.value = window.pdw_productOptionsDosage[arguments[0]];
        }
    };
}

function handleChangeForNewAnalyse(commentField) {
    return function() {
        if (commentField && window.pdw_analyseOptionsData) {
            commentField.value = window.pdw_analyseOptionsData[arguments[0]];
        }
    };
}

function handlePhotoFilePreview(elements = []){
    window.pdwLoadPhotoFile = function (event) {
        const previewContainer = document.getElementById('photo-preview-container');
        const preview = document.getElementById('photo-preview');

        if (!previewContainer || !preview) {return false;}
        preview.src = URL.createObjectURL(event.target.files[0]);
        previewContainer.classList.remove('visually-hidden');
        preview.onload = function () {
            URL.revokeObjectURL(preview.src);
        }
    }
}

function handleRepeaterItemRemove(removeBtnContainerElem, noBtnContainerElem, yesBtnContainerElem, itemToRemove) {
    removeBtnContainerElem.addEventListener('click', function (event) {
        event.preventDefault();
        document.querySelectorAll('.product-delete-confirm').forEach(e => e.classList.add('d-none'));
        document.querySelectorAll('.product-delete').forEach(e => e.classList.remove('d-none'));
        noBtnContainerElem.classList.remove('d-none');
        yesBtnContainerElem.classList.remove('d-none');
        removeBtnContainerElem.classList.add('d-none');
    });

    noBtnContainerElem.addEventListener('click', function (event) {
        event.preventDefault();
        yesBtnContainerElem.classList.add('d-none');
        noBtnContainerElem.classList.add('d-none');
        removeBtnContainerElem.classList.remove('d-none');
    });

    yesBtnContainerElem.addEventListener('click', function (event) {
        event.preventDefault();
        itemToRemove.remove();
    });
}

function handleFilterFormSubmit(elements = []){
    window.filterFormSubmit =  function(formId, submitName, submitValue){
        document.querySelector("form#" + formId + " [type='submit'][name='" + submitName + "'][value='" + submitValue + "']").click();
    }
}

function addPathologyProduct(productId = '', dosageValue = '', dosageDurationValue = '', pathologyI = '0') {
    const containerHtml = document.getElementById(`pathology-treatment-${pathologyI}`);

    if (!containerHtml) {return false;}

    const i = uuidv4();
    let templateContainer = document.getElementById('pathology-product-template');
    let template = templateContainer.innerHTML;
    if (!template) {return false;}
    template = template.replaceAll('${i}', i.toString());
    template = template.replaceAll('${pathologyI}', pathologyI.toString());
    const productDivElem = document.createElement('div');

    productDivElem.classList.add('row', 'mb-1', 'product-item');
    productDivElem.id = "product-item-" + i;
    productDivElem.innerHTML += template;

    containerHtml.appendChild(productDivElem);

    const removeBtnContainerElem = document.getElementById('product-delete-' + i);
    const noBtnContainerElem = document.getElementById('product-delete-no-' + i);
    const yesBtnContainerElem = document.getElementById('product-delete-yes-' + i);
    const productItem = document.getElementById('product-item-' + i);
    const tomSelectProduct = document.getElementById('tom-select-products-' + i);
    const dosageField = document.getElementById('dosage-' + i);
    const dosageDuration = document.getElementById('dosage_duration-' + i);

    tomSelectProduct.value = productId;
    dosageField.value = dosageValue;
    dosageDuration.value = dosageDurationValue;

    tomSelectProduct && new TomSelect(tomSelectProduct, {
        onChange: handleChangeForNewProduct(dosageField),
    });

    handleRepeaterItemRemove(removeBtnContainerElem, noBtnContainerElem, yesBtnContainerElem, productItem);
}

function addPrescriptionAnalyse(analyseId = '', comment = '') {
    const containerHtml = document.getElementById(`prescription-analyses`);

    if (!containerHtml) {return false;}

    const i = uuidv4();
    let templateContainer = document.getElementById('pathology-analyse-template');
    let template = templateContainer.innerHTML;
    if (!template) {return false;}
    template = template.replaceAll('${i}', i.toString());
    const analyseDivElem = document.createElement('div');

    analyseDivElem.classList.add('row', 'mb-1', 'analyse-item');
    analyseDivElem.id = "analyse-item-" + i;
    analyseDivElem.innerHTML += template;

    containerHtml.appendChild(analyseDivElem);

    const removeBtnContainerElem = document.getElementById('analyse-delete-' + i);
    const noBtnContainerElem = document.getElementById('analyse-delete-no-' + i);
    const yesBtnContainerElem = document.getElementById('analyse-delete-yes-' + i);
    const analyseItem = document.getElementById('analyse-item-' + i);
    const tomSelectAnalyse = document.getElementById('tom-select-analyses-' + i);
    const commentField = document.getElementById('comment-' + i);

    tomSelectAnalyse.value = analyseId;
    commentField.value = comment;

    tomSelectAnalyse && new TomSelect(tomSelectAnalyse, {
        onChange: handleChangeForNewAnalyse(commentField),
    });

    handleRepeaterItemRemove(removeBtnContainerElem, noBtnContainerElem, yesBtnContainerElem, analyseItem);
}

function addPrescriptionPathology(pathologyPrescriptionPivot = {}) {
    const containerHtml = document.getElementById('prescription-pathologies');
    if (!containerHtml) {return false;}

    const i = pathologyPrescriptionPivot.id || uuidv4().toString();
    pathologyPrescriptionPivot.id = i;
    let templateContainer = document.getElementById('prescription-pathology-template');
    let template = templateContainer.innerHTML;
    if (!template) {return false;}

    template = template.replaceAll('${i}', i);
    const pathologyDivElem = document.createElement('div');

    pathologyDivElem.classList.add('row', 'mb-2', 'pathology-item');
    pathologyDivElem.id = "pathology-item-" + i;
    pathologyDivElem.innerHTML += template;

    containerHtml.appendChild(pathologyDivElem);

    const removeBtnContainerElem = document.getElementById(`pathology-delete-${i}`);
    const noBtnContainerElem = document.getElementById(`pathology-delete-no-${i}`);
    const yesBtnContainerElem = document.getElementById(`pathology-delete-yes-${i}`);
    const pathologyItem = document.getElementById(`pathology-item-${i}`);
    const tomSelectPathology = document.getElementById(`tom-select-pathology-${i}`);

    tomSelectPathology.value = pathologyPrescriptionPivot?.pathology_id;
    window[`pdw_prescription_pathology_${i}`] = tomSelectPathology.value;

    addTomSelectsFromId(tomSelectPathology.id, {
        onChange: () => alertBeforePathologyChange(i),
    });

    handleAddPathologyProductBtn(i)
    handleRepeaterItemRemove(removeBtnContainerElem, noBtnContainerElem, yesBtnContainerElem, pathologyItem, i);
    handleExistingPrescriptionPathologyProducts(pathologyPrescriptionPivot);
}

function handleExistingPathologyProducts(i = '0') {
    if (!window.pdw_pathologyTreatments) {return false;}

    window.pdw_pathologyTreatments[i].forEach( p => addPathologyProduct(p.product, p.dosage, p.dosage_duration, i) )
}

function handleExistingPrescriptionAnalyses() {
    if (!window.pdw_prescriptionAnalyses) {return false;}

    window.pdw_prescriptionAnalyses.forEach( analyse => addPrescriptionAnalyse(analyse.id || analyse.analyse, analyse.comment) )
}

function handleExistingPrescriptionPathologyProducts(pathologyPrescriptionPivot) {
    const addPrescriptionProductBtnElem = document.getElementById(`add-pathology-product-${pathologyPrescriptionPivot.id}`);
    const prescriptionProductsLabel = document.getElementById(`products-list-label-${pathologyPrescriptionPivot.id}`);
    const pathologySelect = document.getElementById(`tom-select-pathology-${pathologyPrescriptionPivot.id}`);

    if (pathologySelect?.value || Object.entries(pathologyPrescriptionPivot.products || []).length) {
        addPrescriptionProductBtnElem.classList.remove('d-none');
        prescriptionProductsLabel.classList.remove('d-none');
    }

    if (!pathologyPrescriptionPivot?.products) {return false;}

    if (pathologyPrescriptionPivot?.products.forEach) {
        pathologyPrescriptionPivot.products.forEach( p => addPathologyProduct(p.product, p.dosage, p.dosage_duration, pathologyPrescriptionPivot.id) );
    } else {
        for (const [key, p] of Object.entries(pathologyPrescriptionPivot?.products)) {
            addPathologyProduct(p.product, p.dosage, p.dosage_duration, pathologyPrescriptionPivot.id);
        }
    }

}

function handleExistingPrescriptionTreatments() {
    if (!window.pdw_prescriptionTreatments?.length) {return false;}

    window.pdw_prescriptionTreatments.forEach(function (pathologyPrescriptionPivot, key) {
        window.pdw_prescriptionTreatments[key].id = pathologyPrescriptionPivot.id || uuidv4();
        return addPrescriptionPathology(pathologyPrescriptionPivot);
    })
}

function handleAddPathologyProductBtn(i = '0') {
    const addPathologyProductBtnElem = document.getElementById(`add-pathology-product-${i}`);
    const pathologyProductsContainerElem = document.getElementById(`pathology-treatment-${i}`);


    if (!addPathologyProductBtnElem || !pathologyProductsContainerElem) {return false;}

    addPathologyProductBtnElem.addEventListener('click', function (event) {
        event.preventDefault();
        addPathologyProduct('', '', '', i)
    });
}

function handleAddPrescriptionPathologyBtn() {
    const addPrescriptionPathologyBtnElem = document.getElementById('add-prescription-pathology');
    const PrescriptionPathologiesContainerElem = document.getElementById('prescription-pathologies');
    if (!addPrescriptionPathologyBtnElem || !PrescriptionPathologiesContainerElem) {return false;}

    if (!window.pdw_prescriptionTreatments?.length) {
        addPrescriptionPathology()
    }

    addPrescriptionPathologyBtnElem.addEventListener('click', function (event) {
        event.preventDefault();
        addPrescriptionPathology();
    });
}

function handleAddPrescriptionAnalyseBtn() {
    const addPrescriptionAnalyseBtnElem = document.getElementById('add-prescription-analyse');
    const PrescriptionAnalysesContainerElem = document.getElementById('prescription-analyses');
    if (!addPrescriptionAnalyseBtnElem || !PrescriptionAnalysesContainerElem) {return false;}

    // if (!window.pdw_prescriptionAnalyses?.length) {
    //     addPrescriptionAnalyse()
    // }

    addPrescriptionAnalyseBtnElem.addEventListener('click', function (event) {
        event.preventDefault();
        addPrescriptionAnalyse();
    });
}

function handleFlashSuccess() {
    const pdwSessionToastDuration = 5000;

    setTimeout(() => {
        document.querySelectorAll('.toast.text-bg-success').forEach(el => {
            el.classList.add('showing');
        });
    }, pdwSessionToastDuration);
    setTimeout(() => {
        document.querySelectorAll('.toast.text-bg-success').forEach(el => {
            el.classList.remove('show');
            el.classList.add('hide');
        });
    }, pdwSessionToastDuration + 150);
}

function addTomSelectsFromQuerySelectorAll(querySelectorAllAttr, settings = {}){
    document.querySelectorAll(querySelectorAllAttr).forEach(element => {
        new TomSelect(element, settings);
    });
}

function addTomSelectsFromId(id, settings = {}){
    const element = document.getElementById(id);
    if (element) {
        window['pdw_tomSelectElem_' + id] = new TomSelect(element, settings);
    }
}

function changePrescriptionPathologie(i) {
    const addPrescriptionProductBtnElem = document.getElementById(`add-pathology-product-${i}`);
    const prescriptionProductsLabel = document.getElementById(`products-list-label-${i}`);
    const pathologyProductsContainerElem = document.getElementById(`pathology-treatment-${i}`);
    const pathologyIdFromSelect = document.getElementById(`tom-select-pathology-${i}`).value;
    window[`pdw_prescription_pathology_${i}`] = pathologyIdFromSelect;

    pathologyProductsContainerElem && (pathologyProductsContainerElem.innerHTML = null);


    if (pathologyIdFromSelect && window.pdw_pathologies) {
        addPrescriptionProductBtnElem.classList.remove('d-none');
        prescriptionProductsLabel.classList.remove('d-none');
        window.pdw_pathologyTreatments = [];
        const pathology = window.pdw_pathologies.filter(pathology => pathology.id === pathologyIdFromSelect)[0];
        const products = pathology?.products;

        if (products && products.length) {
            window.pdw_pathologyTreatments[i] = [];

            products.forEach((product, key) => {
                window.pdw_pathologyTreatments[i][key] = {
                    'product': product.id,
                    'dosage': product.pivot.dosage,
                    'dosage_duration': product.pivot.dosage_duration
                };
            });
            handleExistingPathologyProducts(i);
        }
    } else {
        addPrescriptionProductBtnElem.classList.add('d-none')
        prescriptionProductsLabel.classList.add('d-none')
    }
}

function handleConfirmPathologyChangeModalEvent() {
    const confirmModalHtml = document?.getElementById('modal-confirm-pathology-change');
    const yesBtn = document.getElementById('modal-confirm-pathology-change-yes');
    const noBtn = document.getElementById('modal-confirm-pathology-change-no');
    if (!confirmModalHtml || !yesBtn || !noBtn) {return false;}

    yesBtn.addEventListener('click', function (event) {
        changePrescriptionPathologie(confirmModalHtml.dataset.pathoTarget);
    });

    noBtn.addEventListener('click', function (event) {
        const pathologySelect = document.getElementById(`tom-select-pathology-${confirmModalHtml.dataset.pathoTarget}`);
        pathologySelect.value = window[`pdw_prescription_pathology_${confirmModalHtml.dataset.pathoTarget}`];
        window['pdw_tomSelectElem_' + pathologySelect.id].setValue(window[`pdw_prescription_pathology_${confirmModalHtml.dataset.pathoTarget}`], true)
    });
}

function alertBeforePathologyChange(i){
    const confirmModalHtml = document?.getElementById('modal-confirm-pathology-change');
    const confirmModal = new Modal(confirmModalHtml)
    if (!confirmModal || !confirmModalHtml) {return false;}

    confirmModalHtml.dataset.pathoTarget = i;

    if (window[`pdw_prescription_pathology_${i}`]) {
        confirmModal.show();
    } else {
        changePrescriptionPathologie(i);
    }
}

ready(function(){
    handleDeleteModal();
    handleDataFormSubmit();
    handlePhotoFilePreview();
    handleFilterFormSubmit();
    handleFlashSuccess();
    addTomSelectsFromQuerySelectorAll('.tom-select-auto-init');
    handleExistingPathologyProducts();
    handleAddPathologyProductBtn();
    handleExistingPrescriptionTreatments();
    handleAddPrescriptionPathologyBtn();
    handleConfirmPathologyChangeModalEvent();
    handleAddPrescriptionAnalyseBtn();
    handleExistingPrescriptionAnalyses();
});
